import Comment from '@mui/icons-material/Comment';
import ContentCopy from '@mui/icons-material/ContentCopy';
import DataObject from '@mui/icons-material/DataObject';
import EditIcon from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import Flag from '@mui/icons-material/Flag';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOn from '@mui/icons-material/LocationOn';
import PictureInPicture from '@mui/icons-material/PictureInPicture';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import Search from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Icon } from '@mdi/react';
import { mdiAccountTieHatOutline } from '@mdi/js';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {
  Chip,
  Divider,
  Popover,
  Stack,
  TableContainer,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ViewportListRef } from 'react-viewport-list';
import { useEventListener } from 'usehooks-ts';
import { CourtDialog } from '@/components/ActionsDialog/CourtDialog';
import { JsonDialog } from '@/components/ActionsDialog/JsonDialog';
import { LocationDialog } from '@/components/ActionsDialog/LocationDialog';
import { ActionsTableCellDataset } from '@/components/ActionsTable/ActionsTableCell';
import { CommentDialog } from '@/components/Comment/CommentDialog';
import { LoadingOverlay } from '@/components/common/LoadingOverlay';
import { DICTIONARY, TABLE_SORT_ORDER } from '@/constants/dictionary';
import { UIStateContext } from '@/contexts/UIState/context';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
  SPORT_ID,
} from '@/service/constants';
import { FixtureAction, UserPermission } from '@/service/types';
import { copyToClipboard } from '@/utils';
import { throttle } from '@/utils/throttle/throttle';
import { getSport } from '@/service/utils/getSport';
import { DeleteActionDialog } from '@/components/ActionsDialog/DeleteActionDialog';
import { RestoreActionDialog } from '@/components/ActionsDialog/RestoreActionDialog';
import { StreamingDialog } from '@/components/StreamingDialog/StreamingDialog';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { SortBy, useSort } from '@/utils/sort/useSort';
import { MarketsContext } from '@/contexts/markets/context';
import { UNKNOWN_PLAYER_ID } from '@/constants';
import { ActionEvaluationDialog } from '@/components/ActionEvaluationDialog/ActionEvaluationDialog';
import {
  SCORING_WORKER_HOST_ACTION,
  SetActionLatencyEvaluationMsg,
} from '@/workers/scoring/types';
import { useSlaBreachActionEvaluation } from '@/service/hooks/useSlaBreachActionEvaluation';
import { isChangeableActionTypeId } from '../EditAction/utils';
import { EditActionDialog } from '../ActionsDialog/EditActionDialog';
import { PermissionsChecker } from '../PermissionsChecker/PermissionsChecker';
import { hasPermissionsToElement } from '../PermissionsChecker/utils';
import { KeyboardButton } from '../common/KeyboardButton';
import { useActionDialog } from '../ActionsDialog/useActionDialog';
import { ActionsTableActionBar } from './ActionBar/ActionBar';
import {
  FILTER_DISPLAY_NAME,
  FILTER_PROPERTY,
} from './ActionFilters/constants';
import { ActionFilter } from './ActionFilters/useActionsFilters';
import { removeGenericFilter } from './ActionFilters/utils';
import { ActionsTableBody, SCROLL_TO_TOP_OFFSET } from './ActionsTableBody';
import { ActionsTableHeader } from './ActionsTableHeader';
import { ContextMenu, ContextMenuItem } from './ContextMenu';
import { BUTTON_NAME, NOT_DELETABLE_ACTIONS } from './constants';
import {
  ActionsTableContext,
  ActionsTableContextType,
} from './context/ActionsTableContext';
import {
  findCellInPath,
  findDeleteAction,
  findRowInPath,
  generateSlaBreach,
  getCellDataset,
  getRowActionId,
  isActionDeleted,
  isChangeableByMarket,
  parseCellDataValue,
  prepareFlagUpdatePayloads,
} from './utils';
import { FiltersDrawer } from './ActionFilters/FiltersDrawer';
import { TemplateSwitcher } from './ActionFilters/Template/TemplateSwitcher/TemplateSwitcher';

export const getCellExtraMenuItemLabel = (
  { label, filterValue, columnName }: ActionsTableCellDataset,
  text?: string,
) => {
  const itemLabelValue = label || filterValue || 'none';

  return (
    <>
      {text || `${DICTIONARY.COMMON.FILTER_BY}:`}
      <Chip
        sx={{ cursor: 'inherit' }}
        size='small'
        label={`${columnName}: ${itemLabelValue}`}
      />
    </>
  );
};

export interface ActionsTableProps {
  disableFilters?: boolean;
}

export const ActionsTable = ({ disableFilters = false }: ActionsTableProps) => {
  const {
    state: { fixtureSummary, deletedActionIds, fixtureId },
    fixtureConfigState: { fixtureConfig },
    supportedSportsState: { supportedSports },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const { marketsPerAction, marketRelatedActionTypes } =
    useContext(MarketsContext);

  const {
    setHotkeysDisabled,
    showRowEditPopup,
    setShowRowEditPopup,
    isSlaBreachActionSelected,
    showRowLatency,
    setShowRowLatency,
  } = useContext(UIStateContext);

  const { openActionDialog, actionDialog, setActionDialog } = useActionDialog();

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fabSpacing = { top: theme.spacing(5), bottom: theme.spacing(8) };
  const sportId = getSport(fixtureConfig)?.id;
  const isSoccer = sportId === SPORT_ID.SOCCER;
  /*TODO: move this logic to UserContext after permissions re-review*/
  const { actions, displayActions, filters, setFilters, dispatchFlagUpdate } =
    useContext<ActionsTableContextType>(ActionsTableContext);
  const { permissions } = useContext(RolePermissionsContext);

  const { dispatch: updateActionsFlag, isLoading: isActionsFlagUpdating } =
    dispatchFlagUpdate;

  const { dispatch: setLatencyEvaluation } =
    useDispatchWithResponse<SetActionLatencyEvaluationMsg>(
      SCORING_WORKER_HOST_ACTION.SET_ACTION_LATENCY_EVALUATION,
    );

  const { mutate } = useSlaBreachActionEvaluation({
    fixtureId,
  });
  /**
   * Internal state to keep actions collection
   * and avoid mutating the `actions` coming as a prop
   * Use `setDisplayActions` when applying filtering.
   * Use `displayActions` instead `actions` inside this component.
   */

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { sortBy, setSortBy, sortOrder, setSortOrder } = useSort();

  useEffect(() => {
    setIsLoading(!(fixtureConfig && fixtureSummary && actions !== undefined));
  }, [fixtureSummary, fixtureConfig, actions]);

  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [contextMenuItems, setContextMenuItems] = useState<ContextMenuItem[]>(
    [],
  );
  const [isScrollTopVisible, setIsScrollTopVisible] = useState<boolean>(false);
  const [hoverAction, setHoverAction] = useState<FixtureAction | undefined>(
    undefined,
  );

  const viewportRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<ViewportListRef | null>(null);

  const onListScroll = (event: Event) => {
    if (!event || !event.target || event.target !== viewportRef.current) return;
    const { scrollTop } = event.target as HTMLElement;
    if (scrollTop >= SCROLL_TO_TOP_OFFSET && !isScrollTopVisible) {
      return setIsScrollTopVisible(true);
    }
    if (scrollTop < SCROLL_TO_TOP_OFFSET && isScrollTopVisible) {
      return setIsScrollTopVisible(false);
    }
    return;
  };

  useEventListener('scroll', throttle(onListScroll, 500), viewportRef);

  useEffect(() => {
    if (isSlaBreachActionSelected) return;

    handleKeyboardKeyPressForEditAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRowEditPopup]);

  useEffect(() => {
    if (!hoverAction?.isSlaBreachApplicable && !isSlaBreachActionSelected) {
      setShowRowLatency(false);
      return;
    }

    handleKeyboardKeyPress(
      showRowLatency,
      'FixturePage.QA.SlaBreachActionEvaluation',
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRowLatency, isSlaBreachActionSelected]);

  const handleKeyboardKeyPressForEditAction = () => {
    if (
      showRowEditPopup &&
      hoverAction &&
      canEditAction(hoverAction) &&
      (isChangeableByMarket({
        actionTypeId: hoverAction.fixtureActionTypeId,
        marketRelatedActionTypes,
        marketsPerAction,
      }) ||
        hoverAction.playerId === UNKNOWN_PLAYER_ID) &&
      hasPermissionsToElement('FixturePage.MatchData.EditAction', permissions)
    ) {
      openActionDialog(
        EditActionDialog,
        hoverAction,
        listRef,
        isScrollTopVisible,
      );
    } else {
      setShowRowEditPopup(false);
    }
  };

  const handleKeyboardKeyPress = (
    showRow: boolean,
    actionPermission: UserPermission['name'],
  ) => {
    if (!showRow || !hoverAction) {
      setShowRowEditPopup(false);
      setShowRowLatency(false);
      return;
    }

    const isEditAction =
      actionPermission === 'FixturePage.MatchData.EditAction';
    const actionDialog = isEditAction
      ? EditActionDialog
      : ActionEvaluationDialog;
    const hasPermission = hasPermissionsToElement(
      actionPermission,
      permissions,
    );

    if (!hasPermission) {
      isEditAction ? setShowRowEditPopup(false) : setShowRowLatency(false);
      return;
    }
    if (isEditAction && !canEditAction(hoverAction)) {
      setShowRowEditPopup(false);
      return;
    }

    openActionDialog(actionDialog, hoverAction, listRef, isScrollTopVisible);
  };

  const closeContextMenu = () => {
    setHotkeysDisabled(false);
    setContextMenuItems([]);
  };

  const canEditAction = (action: FixtureAction) => {
    const { actionId, fixtureActionTypeId, sendTypeId, fixtureSeqNum } = action;

    const isEditableSendType =
      sendTypeId === MATCH_ACTION_SEND_TYPE.CONFIRMED ||
      sendTypeId === MATCH_ACTION_SEND_TYPE.UPDATED;

    const isPlayerRequired = fixtureConfig?.fixtureOptions.actionButtons.some(
      ({ playerRequired, actionType }) =>
        actionType.id === fixtureActionTypeId && playerRequired,
    );

    const isChangableTeam = fixtureConfig?.fixtureOptions.actionButtons.some(
      ({ changeableTeam, actionType }) =>
        actionType.id === fixtureActionTypeId && changeableTeam,
    );

    const isChangeableAction = isChangeableActionTypeId({
      fixtureActionTypeId: action.fixtureActionTypeId,
      fixtureConfig,
    });

    const firstActionFixtureSeqNum = actions?.find(
      (action) => action.actionId === actionId,
    )?.fixtureSeqNum as number;

    return (
      (isPlayerRequired || isChangeableAction || isChangableTeam) &&
      isEditableSendType &&
      fixtureSeqNum >= firstActionFixtureSeqNum
    );
  };

  const openContextMenu = ({
    event,
    clickedRowActionId,
    extraMenuItems = [],
  }: {
    event: MouseEvent<HTMLElement>;
    clickedRowActionId: FixtureAction['id'];
    extraMenuItems?: ContextMenuItem[];
  }) => {
    const action = findDisplayActionById(clickedRowActionId);
    if (!action) return;

    setHotkeysDisabled(true);
    const { actionId, flag, fixtureSeqNum, fixtureActionTypeId, playerId } =
      action;

    const isChangeableAction = isChangeableByMarket({
      actionTypeId: fixtureActionTypeId,
      marketsPerAction,
      marketRelatedActionTypes,
    });

    const firstActionFixtureSeqNum = actions?.find(
      (action) => action.actionId === actionId,
    )?.fixtureSeqNum as number;

    const deleteAction = findDeleteAction(deletedActionIds, action);

    const isDeletableRestoredActionBySport =
      sportId && supportedSports?.includes(sportId);

    const isDeletedParentAction = !action.parentFixtureActionId
      ? false
      : actions?.find(
          (parentAction) =>
            parentAction.actionId === action.parentFixtureActionId,
        )?.sendTypeId === MATCH_ACTION_SEND_TYPE.DELETED;

    const isValidAction =
      isDeletableRestoredActionBySport &&
      (action.sendTypeId === MATCH_ACTION_SEND_TYPE.CONFIRMED ||
        action.sendTypeId === MATCH_ACTION_SEND_TYPE.UPDATED) &&
      !isActionDeleted(action, deleteAction) &&
      !NOT_DELETABLE_ACTIONS.includes(action.fixtureActionTypeId) &&
      fixtureSeqNum >= firstActionFixtureSeqNum &&
      isChangeableAction &&
      !isSlaBreachActionSelected;

    const isDeletableAction = () => {
      const isEndMatch =
        action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.END_FIXTURE;

      if (!isEndMatch) {
        return isValidAction;
      } else {
        const postCheckMatchComplete = actions?.filter(
          (act) =>
            act.fixtureActionTypeId ===
            FIXTURE_ACTION_TYPE.POST_MATCH_CHECK_COMPLETE,
        );

        //if post match check complete above end match, we can't delete end match.

        return (
          postCheckMatchComplete?.every(
            (checkedAction) =>
              action?.fixtureSeqNum > checkedAction?.fixtureSeqNum,
          ) && isValidAction
        );
      }
    };

    const isRestoredAction =
      isDeletableRestoredActionBySport &&
      action.sendTypeId === MATCH_ACTION_SEND_TYPE.DELETED &&
      action.fixtureActionTypeId !== FIXTURE_ACTION_TYPE.SUBSTITUTION_IN &&
      action.fixtureActionTypeId !== FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT &&
      !isActionDeleted(action, deleteAction) &&
      !isDeletedParentAction &&
      isChangeableAction &&
      !isSlaBreachActionSelected;

    const newContextMenuItems = [
      ...extraMenuItems,
      ...(!disableFilters
        ? [
            {
              Icon: Search,
              label: `${DICTIONARY.COMMON.FILTER_BY} Action ID`,
              onClick: () => {
                setFilters([
                  {
                    displayName: FILTER_DISPLAY_NAME.ACTION_ID,
                    displayValue: actionId,
                    property: FILTER_PROPERTY.ACTION_ID,
                    value: actionId,
                  },
                ]);
                closeContextMenu();
              },
              permissionName: 'FixturePage.MatchData.FilterByActionId',
            },
          ]
        : []),
    ];

    newContextMenuItems.push({
      Icon: DataObject,
      label: `${DICTIONARY.COMMON.SHOW} JSON`,
      key: 'show-json',
      onClick: () => {
        openActionDialog(JsonDialog, action);
        closeContextMenu();
      },
      permissionName: 'FixturePage.MatchData.ShowJSON',
    });

    if (!isSlaBreachActionSelected) {
      newContextMenuItems.push({
        Icon: ContentCopy,
        key: 'copy-action-id',
        label: `${DICTIONARY.COMMON.COPY} Action ID`,
        onClick: () => {
          copyToClipboard(actionId).then(() => {
            enqueueSnackbar(DICTIONARY.SUCCESS.COPIED_TO_CLIPBOARD, {
              variant: 'success',
            });
            closeContextMenu();
          });
        },
        permissionName: 'FixturePage.MatchData.CopyActionId',
      });
    }

    if (
      canEditAction(action) &&
      (isChangeableAction || playerId === UNKNOWN_PLAYER_ID) &&
      !isSlaBreachActionSelected
    ) {
      newContextMenuItems.push({
        Icon: EditIcon,
        label: (
          <>
            {DICTIONARY.COMMON.EDIT_ACTION} <KeyboardButton theKey='e' />
          </>
        ),
        onClick: () => {
          openActionDialog(
            EditActionDialog,
            action,
            listRef,
            isScrollTopVisible,
          );
          closeContextMenu();
        },
        permissionName: 'FixturePage.MatchData.EditAction',
      });

      if (flag !== null) {
        newContextMenuItems.push({
          Icon: Flag,
          label: DICTIONARY.COMMON.FLAG[flag.state],
          key: 'flag',
          isLoading: isActionsFlagUpdating,
          onClick: () => {
            if (!actions) return;
            const flagUpdatePayloads = prepareFlagUpdatePayloads(
              action,
              actions,
            );
            if (flagUpdatePayloads === null) return;
            updateActionsFlag(flagUpdatePayloads, {
              successMessage: DICTIONARY.SUCCESS.FLAG_UPDATED,
            }).finally(closeContextMenu);
          },
          permissionName: 'FixturePage.MatchData.ConfirmFlag',
        });
      }
    }
    newContextMenuItems.push({
      Icon: Comment,
      label: DICTIONARY.COMMON.COMMENT,
      key: 'comment-action',
      onClick: () => {
        openActionDialog(CommentDialog, action);
        closeContextMenu();
      },
      permissionName: 'FixturePage.MatchData.Comment',
    });

    if (action.isSlaBreachApplicable || isSlaBreachActionSelected) {
      newContextMenuItems.push({
        Icon: AccessTimeIcon,
        key: 'action-evaluation',
        label: (
          <>
            {DICTIONARY.COMMON.ACTION_EVALUATION} <KeyboardButton theKey='l' />
          </>
        ),
        onClick: () => {
          openActionDialog(ActionEvaluationDialog, action);
          closeContextMenu();
        },
        permissionName: 'FixturePage.QA.SlaBreachActionEvaluation',
      });
    }

    if (
      action.slaBreachSummary?.isEvaluatedBySupervisor &&
      isSlaBreachActionSelected
    ) {
      newContextMenuItems.push({
        Icon: () => <Icon path={mdiAccountTieHatOutline} size={0.7} />,
        label: DICTIONARY.COMMON.CONFIRM_ACTION_EVALUATION,
        onClick: async () => {
          if (!action || !action.slaBreachSummary) return;
          const updateSla = generateSlaBreach(action);

          updateSla &&
            (await setLatencyEvaluation(updateSla)
              .then(() => mutate())
              .catch((error) => {
                return enqueueSnackbar(error.messsage, { variant: 'error' });
              })
              .finally(() => closeContextMenu()));
        },
        permissionName: 'FixturePage.QA.EvaluationOfficer',
      });
    }

    if (action.metadata && !isSlaBreachActionSelected) {
      action.metadata.longitude &&
        action.metadata.latitude &&
        newContextMenuItems.push({
          Icon: LocationOn,
          label: DICTIONARY.COMMON.DEVICE_LOCATION,
          onClick: () => {
            openActionDialog(LocationDialog, action);
            closeContextMenu();
          },
          permissionName: 'FixturePage.MatchData.DeviceLocation',
        });

      action.metadata.courtPosition &&
        newContextMenuItems.push({
          Icon: PictureInPicture,
          label: DICTIONARY.COMMON.COURT_POSITION,
          onClick: () => {
            openActionDialog(CourtDialog, action);
            closeContextMenu();
          },
          permissionName: 'FixturePage.MatchData.CourtPosition',
        });
    }

    if (isDeletableAction()) {
      newContextMenuItems.push({
        Icon: DeleteOutlineIcon,
        label: DICTIONARY.COMMON.DELETE_ACTION,
        onClick: () => {
          openActionDialog(DeleteActionDialog, action);
          closeContextMenu();
        },
        permissionName: 'FixturePage.MatchData.DeleteAction',
      });
    }

    if (isRestoredAction) {
      newContextMenuItems.push({
        Icon: RestoreFromTrashIcon,
        label: DICTIONARY.COMMON.RESTORE_ACTION,
        onClick: () => {
          openActionDialog(RestoreActionDialog, action);
          closeContextMenu();
        },
        permissionName: 'FixturePage.MatchData.RestoreAction',
      });
    }

    setContextMenuPosition({ top: event.pageY + 5, left: event.pageX });
    setContextMenuItems(newContextMenuItems);
  };

  const findDisplayActionById = useCallback(
    (actionId: FixtureAction['id']) =>
      displayActions.find(({ id }) => id === actionId),
    [displayActions],
  );

  /**
   * Since dialog props are stored in state
   * we need to update it programatically to make
   * dialogs re-render on changes.
   */
  useEffect(() => {
    if (!actionDialog.dialog || !actionDialog.props) return;
    const oldAction = actionDialog.props.action;
    const newAction = findDisplayActionById(oldAction.id) || oldAction;
    if (oldAction === newAction) return;

    setActionDialog({
      ...actionDialog,
      props: {
        ...actionDialog.props,
        action: newAction,
      },
    });
    // We only need this to run on actions changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayActions]);

  const onTableClick = async (
    event: MouseEvent<HTMLTableSectionElement>,
  ): Promise<void> => {
    const path = event.nativeEvent.composedPath() as HTMLElement[];

    const button = path.find(({ tagName }) => tagName === 'BUTTON') as
      | HTMLButtonElement
      | undefined;
    if (!button) return;

    const clickedRowElement = findRowInPath(path);
    if (!clickedRowElement) return;

    const clickedRowActionId = getRowActionId(clickedRowElement);
    if (!clickedRowActionId) return;

    if (button.name === BUTTON_NAME.MORE) {
      openContextMenu({ event, clickedRowActionId });
      return;
    }

    if (button.name === BUTTON_NAME.EVALUATION_SUPERVISOR) {
      const action = findDisplayActionById(clickedRowActionId);
      if (!action || !action.slaBreachSummary) return;

      const updateSla = generateSlaBreach(action);
      updateSla &&
        (await setLatencyEvaluation(updateSla)
          .then(() => mutate())
          .catch((error) => {
            return enqueueSnackbar(error.messsage, { variant: 'error' });
          }));
    }

    if (button.name === BUTTON_NAME.LATENCY) {
      const action = findDisplayActionById(clickedRowActionId);
      if (!action) return;
      openActionDialog(ActionEvaluationDialog, action);
    }
    if (button.name === BUTTON_NAME.COMMENT) {
      const action = findDisplayActionById(clickedRowActionId);
      if (!action) return;
      openActionDialog(CommentDialog, action);
    }
    if (button.name === BUTTON_NAME.STREAM) {
      const action = findDisplayActionById(clickedRowActionId);
      if (!action) return;
      openActionDialog(StreamingDialog, action);
    }
    if (button.name === BUTTON_NAME.FLAG) {
      const action = findDisplayActionById(clickedRowActionId);
      if (!actions || !action || action.flag === null) return;
      const flagUpdatePayloads = prepareFlagUpdatePayloads(action, actions);
      if (flagUpdatePayloads === null) return;
      updateActionsFlag(flagUpdatePayloads, {
        successMessage: DICTIONARY.SUCCESS.FLAG_UPDATED,
      });
    }
  };

  const onTableRightClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    const path = event.nativeEvent.composedPath() as HTMLElement[];
    const clickedRowActionId = getRowActionId(findRowInPath(path));
    if (!clickedRowActionId) return;
    const cellData = getCellDataset(findCellInPath(path));
    const extraMenuItems: ContextMenuItem[] = [];
    if (!disableFilters && cellData && cellData.filterProperty) {
      const property = cellData.filterProperty;
      const filterValue = parseCellDataValue(
        cellData.filterValue,
        cellData.filterValueType,
      );
      const newFilter = {
        displayName: cellData.columnName || '',
        displayValue: cellData.label || filterValue,
        property,
        value: filterValue,
        exclude: false,
      } as ActionFilter;
      const thisPropertyFilters = filters.filter(
        (filter) => filter.property === newFilter.property,
      );
      if (
        filters.length > 0 &&
        !thisPropertyFilters.some(({ value }) => value === filterValue)
      ) {
        const addAlsoItem = {
          Icon: PlaylistAdd,
          label: getCellExtraMenuItemLabel(
            cellData,
            `${DICTIONARY.COMMON.ADD_FILTER}:`,
          ),
          key: 'add-filter',
          onClick: () => {
            const newFilters = removeGenericFilter(
              filters,
              FILTER_PROPERTY.PLAYER_ID,
            );
            setFilters([...newFilters, { ...newFilter, exclude: false }]);
            closeContextMenu();
          },
        };
        const hideAlsoItem = {
          Icon: PlaylistAdd,
          label: getCellExtraMenuItemLabel(cellData, 'Hide also: '),
          key: 'add-exclude-filter',
          onClick: () => {
            const newFilters = removeGenericFilter(
              filters,
              FILTER_PROPERTY.PLAYER_ID,
            );
            setFilters([...newFilters, { ...newFilter, exclude: true }]);
            closeContextMenu();
          },
        };

        if (thisPropertyFilters.length) {
          const isExcludeMode =
            (thisPropertyFilters[0] && thisPropertyFilters[0].exclude) ||
            undefined;

          extraMenuItems.push(isExcludeMode ? hideAlsoItem : addAlsoItem);
        } else {
          extraMenuItems.push(addAlsoItem);
          extraMenuItems.push(hideAlsoItem);
        }
      }

      extraMenuItems.push({
        Icon: FilterList,
        label: getCellExtraMenuItemLabel(cellData),
        key: 'filter-exclusive',
        onClick: () => {
          setFilters([newFilter]);
          closeContextMenu();
        },
      });
      extraMenuItems.push({
        Icon: FilterList,
        label: getCellExtraMenuItemLabel(cellData, 'Hide: '),
        key: 'hide-exclusive',
        onClick: () => {
          setFilters([{ ...newFilter, exclude: true }]);
          closeContextMenu();
        },
      });
    }
    openContextMenu({ event, clickedRowActionId, extraMenuItems });
  };

  const handleRequestSort = (_: MouseEvent<unknown>, sortPproperty: SortBy) => {
    const isAsc =
      sortBy.primary === sortPproperty.primary &&
      sortOrder === TABLE_SORT_ORDER.ASC;
    setSortOrder(isAsc ? TABLE_SORT_ORDER.DESC : TABLE_SORT_ORDER.ASC);

    setSortBy(sortPproperty);
  };

  return (
    <Stack sx={{ height: '100%' }} aria-label={ARIA_LABEL.ACTIONS_TABLE}>
      {!disableFilters && <TemplateSwitcher />}
      <ActionsTableActionBar
        disableFilters={disableFilters}
        fixtureSummary={fixtureSummary}
        fixtureConfig={fixtureConfig}
      />
      <Divider flexItem variant='fullWidth' />
      <TableContainer
        sx={{
          flex: 1,
          paddingBottom: fabSpacing.bottom,
          zIndex: 1,
        }}
        ref={viewportRef}
      >
        <Stack
          sx={{
            minWidth: 900,
          }}
        >
          <ActionsTableHeader
            sortOrder={sortOrder}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
            isSoccer={isSoccer}
          />

          <ActionsTableBody
            listRef={listRef}
            onTableRightClick={onTableRightClick}
            isScrollTopVisible={isScrollTopVisible}
            onTableClick={onTableClick}
            fixtureConfig={fixtureConfig}
            viewportRef={viewportRef}
            fabSpacing={fabSpacing}
            sportId={sportId}
            setHoverAction={setHoverAction}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
        </Stack>
      </TableContainer>
      <Popover
        open={!!contextMenuItems.length}
        onClose={closeContextMenu}
        anchorReference='anchorPosition'
        anchorPosition={contextMenuPosition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        transitionDuration={0}
      >
        <PermissionsChecker name='FixturePage.MatchData.KebabMenu'>
          <ContextMenu items={contextMenuItems}></ContextMenu>
        </PermissionsChecker>
      </Popover>
      <LoadingOverlay isLoading={isLoading} />
      {!disableFilters && <FiltersDrawer />}
      {actionDialog.dialog && actionDialog.props && (
        <actionDialog.dialog {...actionDialog.props} />
      )}
    </Stack>
  );
};
