import { FIXTURE_ACTION_TYPE } from '@/service/constants';

export const NOT_DELETABLE_ACTIONS = [
  FIXTURE_ACTION_TYPE.TEAM_COLOURS,
  FIXTURE_ACTION_TYPE.START_CLOCK,
  FIXTURE_ACTION_TYPE.STOP_CLOCK,
  FIXTURE_ACTION_TYPE.ADJUST_CLOCK,
  FIXTURE_ACTION_TYPE.START_DELAY,
  FIXTURE_ACTION_TYPE.END_DELAY,
  FIXTURE_ACTION_TYPE.SUBSTITUTION_IN,
  FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT,
  FIXTURE_ACTION_TYPE.GAIN_POSSESSION,
  FIXTURE_ACTION_TYPE.VIDEO_CHECK,
  FIXTURE_ACTION_TYPE.DISTRESS,
  FIXTURE_ACTION_TYPE.ATTENDANCE,
  FIXTURE_ACTION_TYPE.POST_MATCH_CHECK_COMPLETE,
  FIXTURE_ACTION_TYPE.STATUS_UPDATE,
  FIXTURE_ACTION_TYPE.MATCH_CONDITIONS,
  FIXTURE_ACTION_TYPE.DEVICE_LOCATION,
  FIXTURE_ACTION_TYPE.LINEUPS,
  FIXTURE_ACTION_TYPE.PLAYERS_READY,
  FIXTURE_ACTION_TYPE.START_FIXTURE,
  FIXTURE_ACTION_TYPE.START_PERIOD,
  FIXTURE_ACTION_TYPE.END_PERIOD,
];

export const DEFAULT_NOT_VERIFIED_LATENCY_ACTIONS = [
  FIXTURE_ACTION_TYPE.GOAL,
  FIXTURE_ACTION_TYPE.GOAL_DISALLOWED,
  FIXTURE_ACTION_TYPE.RED_CARD,
  FIXTURE_ACTION_TYPE.PENALTY_AWARDED,
  FIXTURE_ACTION_TYPE.PENALTY_MISSED,
  FIXTURE_ACTION_TYPE.YELLOW_CARD,
];

export const SHEET_NAME = {
  MATCH_DATA: 'Match Data',
  FIXTURE: 'Fixture',
  HOME_TEAM_STATS: 'Home Team Stats',
  AWAY_TEAM_STATS: 'Away Team Stats',
};

export const TABLE_CLASS_NAMES = {
  ROW: 'action-row',
  CELL: 'action-cell',
};

export const BUTTON_NAME = {
  FLAG: 'flag-button',
  MORE: 'more-button',
  COMMENT: 'comment-button',
  EVALUATION_SUPERVISOR: 'evaluation-supervisor-button',
  STREAM: 'stream-button',
  LATENCY: 'latency-button',
} as const;

export const ACTION_ICON_ID = {
  GOAL: 'SportsSoccerIcon',
  RED_CARD: 'RedCardIcon',
  YELLOW_CARD: 'YellowCardIcon',
  SECOND_YELLOW_CARD: 'YellowRedCardIcon',
  PENALTY_AWARDED: 'PenaltyAwardedIcon',
  CORNER_AWARDED: 'CornerAwardedIcon',
  CLOSE_MARKET: 'WarningAmberIcon',
  KICK_OFF: 'SportsIcon',
};
